






















































































































































import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import "vue2-datepicker/index.css";
import {
  DataWarehouse,
  ResponseWarehouse,
} from "@/models/interface/logistic.interface";

import { logisticServices } from "@/services/logistic.service";
import moment from "moment";
import { Component, Ref, Vue } from "vue-property-decorator";
import {
  DATE_FORMAT_YYYY_MM_DD,
  DEFAULT_DATE_FORMAT,
} from "@/models/constants/date.constant";
import { assetsServices } from "@/services/assets.service";
import DatePicker from "vue2-datepicker";
import { salesOrderServices } from "@/services/salesorder.service";
import { debounce } from "@/helpers/debounce";

@Component({
  components: {
    DatePicker: DatePicker,
  },
})
export default class HistoryUnits extends Vue {
  @Ref("form") form;
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  filterForm = {
    branch: "",
    asOfDate: moment(new Date()).format(DEFAULT_DATE_FORMAT),
    yearPlacement: "",
    category: "",
    unitCode: "",
  };
  branchOption = {
    data: [] as DataWarehouse[],
    search: "",
    fetching: true,
  };
  categoryOption = {
    data: [] as any[],
    search: "",
    fetching: true,
  };

  unitCodeOption = {
    data: [] as any[],
    search: "",
    fetching: true,
  };
  loading = {
    find: false as boolean,
  };
  downloadPrintParam = {
    companyName:
      this.$store.state.authStore.authData.companyName ||
      "PT. SATRIA PIRANTI PERKASA",
    branch: "ALL",
    category: "ALL",
    unitCode: "ALL",
    year: "ALL",
    asOfDate: "ALL",
  };

  mounted(): void {
    this.getBranchList();
    this.getCategory();
    this.getUnitCode();
  }

  handleSubmit(): void {
    this.form.validate(valid => {
      if (valid) {
        for (const key in this.downloadPrintParam) {
          if (key != "companyName") this.downloadPrintParam[key] = "ALL";
        }
        if (this.filterForm.branch) {
          this.downloadPrintParam.branch = this.branchOption.data.find(
            item => item.id == this.filterForm.branch
          )?.name as string;
        }
        if (this.filterForm.category) {
          this.downloadPrintParam.category = this.filterForm.category;
        }
        if (this.filterForm.unitCode) {
          this.downloadPrintParam.unitCode = this.unitCodeOption.data.find(
            item => item.id == this.filterForm.unitCode
          ).unitCode as string;
        }
        if (this.filterForm.yearPlacement) {
          this.downloadPrintParam.year = moment(
            new Date(this.filterForm.yearPlacement).getFullYear() +
              "-12" +
              "-31"
          ).format(DATE_FORMAT_YYYY_MM_DD);
        }
        if (this.filterForm.asOfDate) {
          this.downloadPrintParam.asOfDate = moment(
            new Date(this.filterForm.asOfDate).getFullYear() + "-12" + "-31"
          ).format(DATE_FORMAT_YYYY_MM_DD);
        }
        this.getReportUnitPosition();
      }
    });
  }
  getUnitCode() {
    const params: RequestQueryParamsModel = {
      limit: 10,
      page: 0,
    };
    if (this.unitCodeOption.search)
      params.search = `unitCode~*${this.unitCodeOption.search}*`;
    this.unitCodeOption.fetching = true;
    assetsServices
      .listMasterAsset(params)
      .then(response => {
        this.unitCodeOption.data = response.data;
      })
      .finally(() => (this.unitCodeOption.fetching = false));
  }
  resetForm(): void {
    this.filterForm.branch = "";
    this.filterForm.yearPlacement = "";
    this.filterForm.category = "";
    this.filterForm.unitCode = "";
  }

  getBranchList(): void {
    this.branchOption.fetching = true;
    const param: RequestQueryParamsModel = {
      limit: 20,
      page: 0,
      search: ``,
    };
    if (this.branchOption.search) {
      param.search = `name~*${this.branchOption.search}*`;
    }
    logisticServices
      .listWarehouseBranch(param, "")
      .then((res: ResponseWarehouse) => {
        this.branchOption.data = res.data;
      })
      .finally(() => (this.branchOption.fetching = false));
  }

  getReportUnitPosition(): void {
    let year = moment(
      new Date(this.filterForm.yearPlacement).getFullYear() + "-12" + "-31"
    )
      .set("hour", 23)
      .set("minute", 59)
      .set("second", 59)
      .format();
    const params = {
      branchId: this.filterForm.branch ? this.filterForm.branch : "",
      categoryId: this.filterForm.category ? this.filterForm.category : "",
      assetId: this.filterForm.unitCode ? this.filterForm.unitCode : "",
      date: this.filterForm.yearPlacement
        ? year.split("+")[0].replace("T", " ")
        : "",
      params: `${this.downloadPrintParam.companyName},${this.downloadPrintParam.branch},${this.downloadPrintParam.category},${this.downloadPrintParam.unitCode},${this.downloadPrintParam.year}`,
    } as RequestQueryParamsModel;
    this.loading.find = true;
    salesOrderServices
      .downloadReportHistoryUnits(params)
      .then(res => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "report_history_unit.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => (this.loading.find = false));
  }

  searchDropdown(type, value) {
    if (value) {
      switch (type) {
        case "branch":
          this.branchOption.search = value;
          debounce(() => {
            this.getBranchList();
          }, 200);
          break;
        case "category":
          this.categoryOption.search = value;
          debounce(() => {
            this.getCategory();
          }, 200);
          break;
        case "unitCode":
          this.unitCodeOption.search = value;
          debounce(() => {
            this.getUnitCode();
          }, 200);
          break;
        default:
          break;
      }
    }
  }

  getCategory() {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 100,
    };
    if (this.categoryOption.search)
      params.search = `segment.first~*${this.categoryOption.search}*`;
    this.categoryOption.fetching = true;
    assetsServices
      .listCategory(params)
      .then(response => {
        this.categoryOption.data = response.data
          .map(dataMap => {
            return {
              segment1: dataMap.segments[0].first,
              id: dataMap.segments[0].id,
            };
          })
          .filter(
            (value, index, self) =>
              index === self.findIndex(t => t.segment1 === value.segment1)
          );
      })
      .finally(() => (this.categoryOption.fetching = false));
  }
}
